/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://docs.nvidia.com/cuda/cuquantum/latest/index.html"
  }, "NVIDIA cuQuantum SDK"), "\n(not to be confused with ", React.createElement(_components.a, {
    href: "../what-is-cuda-quantum"
  }, "CUDA Quantum"), ") is a collection of libraries for\nhigh-performance simulation of quantum computers on GPUs. cuQuantum includes, and may refer to:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "cuStateVec, a GPU state-vector simulator,"), "\n", React.createElement(_components.li, null, "cuTensorNet, a GPU tensor-network simulator, and"), "\n", React.createElement(_components.li, null, "the cuQuantum Appliance, a multi-GPU and multi-node compatible Docker container with cuStateVec and cuTensorNet pre-installed."), "\n"), "\n", React.createElement(_components.p, null, "The PennyLane ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/projects/lightning/en/stable/lightning_gpu/device.html"
  }, React.createElement(_components.code, null, "lightning.gpu"), " simulator"), "\nleverages cuStateVec to enable GPU-accelerated state-vector simulation directly within\nPennyLane. In addition, it includes the classically-efficient\n", React.createElement(_components.a, {
    href: "https://pennylane.ai/qml/demos/tutorial_adjoint_diff/"
  }, "adjoint differentiation method"), ",\nallowing for high-performance quantum machine learning workflows.\nFor more details, see our ", React.createElement(_components.a, {
    href: "https://pennylane.ai/performance/"
  }, "PennyLane performance overview"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
